import type { FC, ReactNode } from 'react';
import React from 'react';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N400 } from '@atlaskit/theme/colors';

import { useKeyboardShortcutsState } from './useKeyboardShortcutsState';
import { getShortcutString } from './get-shortcut-string';

const KeyboardShortcutContainerStyles = css({
	display: 'flex',
	paddingBottom: token('space.025', '2px'),
	paddingTop: token('space.025', '2px'),
});

export const KeyboardShortcutContainer = ({ children }: { children?: React.ReactNode }) => (
	<div css={KeyboardShortcutContainerStyles}>{children}</div>
);

const KeyboardShortcutDefaultStyles = css({
	backgroundColor: token('color.background.accent.gray.subtle', N400),
	borderRadius: '2px',
	marginLeft: token('space.025', '2px'),
	marginRight: token('space.025', '2px'),
	padding: `1px ${token('space.100', '8px')}`,
});

const KeyboardShortcutStylesWithContentBefore = css({
	marginLeft: token('space.050', '4px'),
	marginRight: token('space.0', '0px'),
});

export const KeyboardShortcut = ({
	hasContentBefore,
	children,
}: {
	hasContentBefore?: boolean;
	children?: React.ReactNode;
}) => {
	return (
		<div
			css={[
				KeyboardShortcutDefaultStyles,
				hasContentBefore && KeyboardShortcutStylesWithContentBefore,
			]}
		>
			{children}
		</div>
	);
};

export type ShortcutVisualizerProps = {
	shortcut: string;
	contentBefore?: ReactNode;
	isEditorShortcut?: boolean;
};

export const ShortcutVisualizer: FC<ShortcutVisualizerProps> = ({
	shortcut,
	contentBefore,
	isEditorShortcut,
}) => {
	const [areKeyboardShortcutsEnabled] = useKeyboardShortcutsState();
	const shortcutString = getShortcutString(shortcut);

	return (
		<KeyboardShortcutContainer>
			{contentBefore}
			{(areKeyboardShortcutsEnabled || isEditorShortcut) &&
				shortcutString.split(' ').map((shortcutSegment, index) => (
					<KeyboardShortcut key={`${shortcutSegment}-${index}`} hasContentBefore={!!contentBefore}>
						{shortcutSegment}
					</KeyboardShortcut>
				))}
		</KeyboardShortcutContainer>
	);
};
